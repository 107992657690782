/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
// import { useLocation } from "react-router-dom"
// import "../styles/muni.min.css"

import {Helmet} from 'react-helmet'

//Redux
import { useDispatch } from "react-redux"
import { changeLanguage } from "../redux/actions/changeLanguageAction"

// Components
import Navbar from "./Tools/Navbar/Navbar"
import Footer from "./Tools/Footer/Footer"

import favIcon from '../assets/muni_favicon.ico'

  export  const Layout = ({ children }) => {
  //Redux
  const dispatch = useDispatch()
  // Router
  //   const location = useLocation()

  // Setting user's language
  const setUserLanguage = () => {
    // Checking if user already has a language or not
    let currentLanguage = typeof window !== undefined ? localStorage.getItem("language") : null
    // If the user does not already have a language,
    // then we set it according to the browser's language
    let browserLanguage = window.navigator.language
    const langArray = browserLanguage.split("-")[0]
    if (!currentLanguage) {
      if (langArray !== "tr") {
        localStorage.setItem("language", "en")
        dispatch(changeLanguage("en"))
      } else {
        localStorage.setItem("language", "tr")
        dispatch(changeLanguage("tr"))
      }
    } else {
      dispatch(changeLanguage(currentLanguage))
    }
  }

  /* USE EFFECTS */
  useEffect(() => {
    setUserLanguage()

    // Scrolling to top on reload to avoid issues with WOW
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(changeLanguage(localStorage.getItem("language")))
  }, [])
  // localStorage.getItem("language")

  /* */

  return (
    <div>
      <Helmet>
        <title>Muni</title>
        <meta name="icon" href={favIcon} />
        <meta
          name="description"
          content="Rydoo Alternative. Muni helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        <meta
          name="keywords"
          content="expense management, concur, scan receipts, rydoo, per diem, expenses, expense tracker"
        />
        <meta property="og:title" content="Muni" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Rydoo Alternative. Muni helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost." />
        {/* <meta property="og:image" content="" /> */}
        <meta property="og:locale" content="en_UK" />
        <meta property="og:url" content="https://usemuni.com" />
        <link rel="canonical" href="https://usemuni.com" />
        <link rel="icon" href={favIcon} />
      </Helmet>
      <Navbar
      />
        {children}
      <Footer/>
    </div>
  )
}

export default Layout